// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-template-plantilla-entrada-js": () => import("./../../../src/components/template/PlantillaEntrada.js" /* webpackChunkName: "component---src-components-template-plantilla-entrada-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-devocionales-js": () => import("./../../../src/pages/devocionales.js" /* webpackChunkName: "component---src-pages-devocionales-js" */),
  "component---src-pages-estudios-js": () => import("./../../../src/pages/estudios.js" /* webpackChunkName: "component---src-pages-estudios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jesus-js": () => import("./../../../src/pages/jesus.js" /* webpackChunkName: "component---src-pages-jesus-js" */),
  "component---src-pages-preguntas-js": () => import("./../../../src/pages/preguntas.js" /* webpackChunkName: "component---src-pages-preguntas-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-todos-js": () => import("./../../../src/pages/todos.js" /* webpackChunkName: "component---src-pages-todos-js" */)
}

